import React, { Component } from 'react'
import { Col, NavItem, TabContent, TabPane, NavLink, Nav } from 'reactstrap'
import classnames from "classnames";

//import images
import proposals from '../assets/images/coconut.png';
import contract from '../assets/images/palm.png';
import crm from '../assets/images/seaweed.png';
import time from '../assets/images/coffeebean.png';
import invoice from '../assets/images/cloves.png';
import task from '../assets/images/pepper.png';
import { Link } from 'react-router-dom';


export default class Work extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "1",
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle(tab) {
        this.setState({ activeTab: tab });
    }
    render() {
        return (
            <React.Fragment>
                <div className="container mt-100 mt-60">
                    <div className="row">
                        <div className="col-md-4 mt-4 pt-2">
                            <Nav
                                pills
                                justified
                                id="pills-tab"
                                className="nav-pills nav-justified flex-column rounded shadow p-3 mb-0 sticky-bar">
                                <NavItem>
                                    <NavLink
                                        className={classnames(
                                            { active: this.state.activeTab === "1" },
                                            "rounded"
                                        )}
                                        onClick={() => {
                                            this.toggle("1");
                                        }} >
                                        <div className="text-start d-flex align-items-center justify-content-between py-1 px-2">
                                            <h6 className="mb-0"><i className="uil uil-postcard me-2 h5"></i> Coconut</h6>
                                            <i className="uil uil-angle-right-b"></i>
                                        </div>
                                    </NavLink>
                                </NavItem>

                                <NavItem className="mt-2">
                                    <NavLink
                                        className={classnames(
                                            { active: this.state.activeTab === "2" },
                                            "rounded"
                                        )}
                                        onClick={() => {
                                            this.toggle("2");
                                        }}
                                    >
                                        <div className="text-start d-flex align-items-center justify-content-between py-1 px-2">
                                            <h6 className="mb-0"><i className="uil uil-notes me-2 h5"></i> Palm Product</h6>
                                            <i className="uil uil-angle-right-b"></i>
                                        </div>
                                    </NavLink>
                                </NavItem>

                                <NavItem className="mt-2">
                                    <NavLink
                                        className={classnames(
                                            { active: this.state.activeTab === "3" },
                                            "rounded"
                                        )}
                                        onClick={() => {
                                            this.toggle("3");
                                        }}
                                    >
                                        <div className="text-start d-flex align-items-center justify-content-between py-1 px-2">
                                            <h6 className="mb-0"><i className="uil uil-folder-check me-2 h5"></i> Seaweed</h6>
                                            <i className="uil uil-angle-right-b"></i>
                                        </div>
                                    </NavLink>
                                </NavItem>

                                <NavItem className="mt-2">
                                    <NavLink
                                        className={classnames(
                                            { active: this.state.activeTab === "4" },
                                            "rounded"
                                        )}
                                        onClick={() => {
                                            this.toggle("4");
                                        }}
                                    >
                                        <div className="text-start d-flex align-items-center justify-content-between py-1 px-2">
                                            <h6 className="mb-0"><i className="uil uil-clock me-2 h5"></i> Coffee</h6>
                                            <i className="uil uil-angle-right-b"></i>
                                        </div>
                                    </NavLink>
                                </NavItem>

                                <NavItem className="mt-2">
                                    <NavLink
                                        className={classnames(
                                            { active: this.state.activeTab === "5" },
                                            "rounded"
                                        )}
                                        onClick={() => {
                                            this.toggle("5");
                                        }}
                                    >
                                        <div className="text-start d-flex align-items-center justify-content-between py-1 px-2">
                                            <h6 className="mb-0"><i className="uil uil-invoice me-2 h5"></i> Cloves</h6>
                                            <i className="uil uil-angle-right-b"></i>
                                        </div>
                                    </NavLink>
                                </NavItem>

                                <NavItem className="mt-2">
                                    <NavLink
                                        className={classnames(
                                            { active: this.state.activeTab === "6" },
                                            "rounded"
                                        )}
                                        onClick={() => {
                                            this.toggle("6");
                                        }}
                                    >
                                        <div className="text-start d-flex align-items-center justify-content-between py-1 px-2">
                                            <h6 className="mb-0"><i className="uil uil-exchange-alt me-2 h5"></i> Pepper</h6>
                                            <i className="uil uil-angle-right-b"></i>
                                        </div>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>

                        <Col md={8} xs={12} className="mt-4 pt-2">
                            <TabContent id="pills-tabContent" activeTab={this.state.activeTab}>
                                <TabPane tabId="1" className="p-4 rounded shadow" id="developing" role="tabpanel" aria-labelledby="proposal">
                                    <h4 className="mb-1">Coconut Oil</h4>
                                    <p className="text-muted mb-0">Coconut Oil for industrial purposes with a certificate of analysis based on the buyer's specifications.</p>
                                    <h4 className="mb-1 mt-2">Coco Fiber</h4>
                                    <p className="text-muted mt-2 mb-0">We collaborate with Celebes farmers to produce high quality home industrial coco fiber for.</p>
                                    <h4 className="mb-1 mt-2">Bricket</h4>
                                    <p className="text-muted mt-2 mb-0">White and dark copra is available for both domestic and export markets.</p>
                                    <h4 className="mb-1 mt-2">Coco Peat</h4>
                                    <p className="text-muted mt-2 mb-0">We collaborate with Celebes farmers to produce high quality home industrial coco peat.</p>
                                    <h4 className="mb-1 mt-2">Copra Meal</h4>
                                    <p className="text-muted mt-2 mb-0">As a byproduct of copra, is in high demand as animal feed.</p>
                                    <h4 className="mb-1 mt-2">Palm Sugar</h4>
                                    <p className="text-muted mb-0">We collaborate with Celebes farmers to produce high quality home industrial palm sugar.</p>

                                    <div className="mt-4">
                                        <img src={proposals} className="img-fluid" alt="" />
                                    </div>
                                </TabPane>

                                <TabPane tabId="2" className="p-4 rounded shadow" id="data-analise" role="tabpanel" aria-labelledby="contract">
                                    <h4 className="mb-1">Palm Kernel Expeller</h4>
                                    <p className="text-muted mb-0">Palm Kernel Expeller is a by-product of the extraction process of palm oil from the fruit of the palm tree. PKE is a high-quality feedstock with high levels of crude protein and moderate energy levels, and it can be safely fed to a variety of livestock as a supplement.</p>
                                    <h4 className="mb-1 mt-2">Palm Shell</h4>
                                    <p className="text-muted mt-2 mb-0">Palm kernel shell is a waste product generated during the processing of palm kernel. The kernel is encased in the shell, which is itself surrounded by a fleshy fiber that contains palm oil. Therefore, the palm kernel shell is obtained after the fleshy fruit is processed to obtain palm oil.</p>
                                    <h4 className="mb-1 mt-2">Palm Oil</h4>
                                    <p className="text-muted mt-2 mb-0">Celeberherbs is dedicated to the production of sustainable palm oil from Indonesia. We are committed to managing the palm oil production process in a sustainable way, which is beneficial for the climate, company, country, and customers.</p>
                                    
                                    <div className="mt-4">
                                        <img src={contract} className="img-fluid" alt="" />
                                    </div>
                                </TabPane>

                                <TabPane tabId="3" className="p-4 rounded shadow" id="security" role="tabpanel" aria-labelledby="crm">
                                    <h4 className="mb-1">Gracilaria SP</h4>
                                    <p className="text-muted mb-0">Gracilaria is a genus of red algae (Rhodophyta) that is noted for its economic value as an agarophyte and as a food for humans and various species of shellfish. It is most commonly cultivated by farmers in Southern Celebes Island.</p>
                                    <h4 className="mb-1 mt-2">Eucheuma Cottonii</h4>
                                    <p className="text-muted mt-2 mb-0">Eucheuma Cottonii seaweed is one of the carrageenophtytes, specifically producing carrageenan, which is a polysaccharide compound. It contains a high dietary fiber content. It is most commonly cultivated by farmers in Northern Celebes Island.</p>
                                    

                                    <div className="mt-4">
                                        <img src={crm} className="img-fluid" alt="" />
                                    </div>
                                </TabPane>

                                <TabPane tabId="4" className="p-4 rounded shadow" id="time-track" role="tabpanel" aria-labelledby="timetracking">
                                    <h4 className="mb-1 mt-2">Toraja Coffee</h4>
                                    <p className="text-muted mt-2 mb-0">Toraja Coffee is an aromatic and flavorful coffee produced in the mountains of South Sulawesi. It is characterized by its robust taste with hints of ripe fruit and dark chocolate.</p>
                                    
                                    

                                    <div className="mt-4">
                                        <img src={time} className="img-fluid" alt="" />
                                    </div>
                                </TabPane>

                                <TabPane tabId="5" className="p-4 rounded shadow" id="invoices" role="tabpanel" aria-labelledby="invoice">
                                    <h4 className="mb-1 mt-2">Cloves</h4>
                                    <p className="text-muted mt-2 mb-0">We specialize in the production of spices in Indonesia, particularly South Sulawesi, and offer high-grade clove for export.</p>
                                    
                                    <div className="mt-4">
                                        <img src={invoice} className="img-fluid" alt="" />
                                    </div>
                                </TabPane>

                                <TabPane tabId="6" className="p-4 rounded shadow" id="task-track" role="tabpanel" aria-labelledby="tasktracking">
                                    <h4 className="mb-1 mt-2">Pepper</h4>
                                    <p className="text-muted mt-2 mb-0">We specialize in the production of spices in Indonesia, particularly South Sulawesi, and offer top quality white pepper and black pepper for export.</p>
                                    


                                    <div className="mt-4">
                                        <img src={task} className="img-fluid" alt="" />
                                    </div>
                                </TabPane>
                            </TabContent>
                        </Col>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
