import React from "react";
import { Link } from "react-router-dom";
import { Col, Container , Row} from "reactstrap";

//import images
import bigLaunch from "../assets/images/spicesherbs.png";
import maintenance from "../assets/images/indonesianfarmer.png";

const Feature = () => {
    return (
      <React.Fragment>
        <Container className="mt-100 mt-60">
          <Row className="align-items-center">
            <Col md={6}>
              <div className="me-lg-5">
                <img src={bigLaunch} className="img-fluid" alt="" />
              </div>
            </Col>

            <Col md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title">
                <h4 className="title mb-4">
                  Our Long-term Vision
                </h4>
                <p className="text-muted">
                To advance and develop as a trading company that can effectively, 
                efficiently, and continuously provide customers/partners with their 
                requests for cassava derivative products. To be at the forefront of trading companies by providing the 
                best value of satisfaction to customers/partners through 
                quality services and products.<br/><br/>

                <div className="section-title">
                <h4 className="title mb-4">
                  Our Mission
                </h4></div>

                </p>
                <ul className="list-unstyled text-muted">
                  <li className="mb-0">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Improving optimal results, maintaining customer trust, and satisfying customers in terms of services, quality, and quantity of goods needed by both domestic and foreign customers.
                  </li>
                  <li className="mb-0">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Holding mutually beneficial cooperation between producers/suppliers, distributors, aggregators, and buyers/customers.
                  </li>
                  <li className="mb-0">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Improving optimal results, maintaining customer trust, and satisfying customers in terms of services, quality, and quantity of goods needed by both domestic and foreign customers.
                  </li>
                </ul>
                
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="mt-100 mt-60">
          <Row className="align-items-center">
            <Col md={6} className="order-1 order-md-2">
              <div className="ms-lg-5">
                <img src={maintenance} className="img-fluid" alt="" />
              </div>
            </Col>

            <Col md={6}  className="order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title">
                <h4 className="title mb-4">
                  About Celebesherbs
                </h4>
                <p className="text-muted">
                Our company has been distributing and supplying palm sugar, bricket, 
                seaweed, coconut oil, coconut broomstick, coffee and cocoa since 2022. 
                For more than a hundred years, our country has been producing the highest 
                quality herbs and spices products around the world. 
                 
                </p>
                <p className="text-muted">
                Therefore, with collaboration and support from local farmers, 
                we can ensure that our products, services and quality remain in prime 
                condition at all times, in order to meet the demand from both local 
                and international markets. 
                </p>

                <p className="text-muted">
                Your needs are always our priority, 
                so there is no need to import palm sugar, bricket, seaweed, coconut oil, 
                coconut broomstick, coffee and cocoa from Indonesia; we can supply any 
                quantity or requests, including customized products such as sizes, packaging, 
                water content level, random mesh granule and many more.
                Listening to and responding to our buyers and customers has been our 
                main motivation from the beginning.
                </p>
                <div className="mt-4">
                  
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
}
export default Feature;
