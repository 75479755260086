import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Components
import SectionTitle from "../components/Shared/SectionTitle";
import TeamBox from "../components/Shared/TeamBox";

//Import Images
import image1 from "../assets/images/rahmatullah.png";
import image2 from "../assets/images/hairuddin.jpg";
import image3 from "../assets/images/03.jpg";
import image4 from "../assets/images/04.jpg";

class Team extends Component {
  state = {
    candidates: [
      {
        id: 1,
        image: image1,
        name: "Rahmatullah, SH., MKn.",
        designation: "CEO",
        salary: "0",
        link: "",
        socialIds: [          
          //{ icon: "instagram", link: "https://instagram.com/rahmat_law" },
          //{ icon: "linkedin", link: "https://www.linkedin.com/in/rahmatlaw/" },
        ],
      },
      {
        id: 2,
        image: image2,
        name: "Hairuddin, SIp",
        designation: "COO",
        salary: "0",
        link: "",
        socialIds: [
          
        ],
      },
      {
        id: 3,
        image: image3,
        name: "Andi Nur Indah, SH",
        designation: "Trustee",
        salary: "",
        link: "",
        socialIds: [
          
        ],
      },
      {
        id: 4,
        image: image4,
        name: "Dev",
        designation: "Web Developer",
        salary: "0",
        link: "",
        socialIds: [
          
        ],
      },
    ],
  };

  render() {
    return (
      <React.Fragment>
        <section className="section bg-light">
          <Container>
            {/* section title */}
            <SectionTitle
              title="Our Team"
              desc="We have an extensive network of reliable suppliers and are committed to delivering premium quality products with outstanding customer service."
            />

            <Row>
              {/* teambox */}
              <TeamBox candidates={this.state.candidates} />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Team;
