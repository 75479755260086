
import React from 'react';
import { Container, Row, Col } from 'reactstrap';

//import Images
import paypal   from "../assets/images/payment/paypals.png";
import master   from "../assets/images/payment/master.png";
import visa     from "../assets/images/payment/visacard.svg";
import mandiri  from "../assets/images/payment/mandiri.svg";
import bitcoin  from "../assets/images/payment/bitcoin.svg";

const Partners = () => {
    const partnersData = [
        {
            id: 1,
            img: paypal,
        },
        {
            id: 2,
            img: master,
        },
        {
            id: 3,
            img: visa,
        },
        {
            id: 4,
            img: mandiri,
        },
        {
            id: 5,
            img: bitcoin,
        },
        
    ]
    return (
        <React.Fragment>
            <section className="py-4 border-bottom border-top">
                <Container>
                    <Row className="justify-content-center">
                    {partnersData.map((item, key) => (
                        <Col key={key} lg={2} md={2} className="col-6 text-center py-4">
                            <img src={item.img} className="avatar avatar-ex-sm" alt="" />
                        </Col>  
                    ))}                                              
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
}

export default Partners;