// React Basic and Bootstrap
import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Collapse,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import { Link } from "react-router-dom";

//Import Icons
import FeatherIcon from "feather-icons-react";

//Import Componenets
import Counter2 from "../components/Shared/counter2";

//import images
import Asset190 from "../assets/images/illustrator/Asset190.svg";
import Asset189 from "../assets/images/illustrator/Asset189.svg";
import Asset192 from "../assets/images/illustrator/Asset192.svg";
import Asset187 from "../assets/images/illustrator/Asset187.svg";
import faq from "../assets/images/illustrator/faq.svg";

// Modal Video
import ModalVideo from "react-modal-video";
import "../../node_modules/react-modal-video/scss/modal-video.scss";

class Faqs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      counters: [
        {
          title: "Happy Client",
          image: Asset190,
          start: 0,
          value: 97,
          postfix: "%",
        },
        { title: "Product Quality", image: Asset189, start: 0, value: 9, postfix: "" },
        {
          title: "Total Products",
          image: Asset192,
          start: 0,
          value: 10,
          postfix: "+",
        },
        {
          title: "Successful Shipments",
          image: Asset187,
          start: 0,
          value: 100,
          postfix: "%",
        },
      ],
      isOpen: false,
      collapse1: true,
      col1: true,
      col2: false,
      col3: false,
      col4: false,
      col5: false,
    };
    this.openModal = this.openModal.bind(this);
    this.t_col1 = this.t_col1.bind(this);
    this.t_col2 = this.t_col2.bind(this);
    this.t_col3 = this.t_col3.bind(this);
    this.t_col4 = this.t_col4.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  t_col1() {
    this.setState({
      col1: !this.state.col1,
      col2: false,
      col3: false,
      col4: false,
    });
  }
  t_col2() {
    this.setState({
      col2: !this.state.col2,
      col1: false,
      col3: false,
      col4: false,
    });
  }
  t_col3() {
    this.setState({
      col3: !this.state.col3,
      col2: false,
      col1: false,
      col4: false,
    });
  }
  t_col4() {
    this.setState({
      col4: !this.state.col4,
      col2: false,
      col3: false,
      col1: false,
    });
  }

  render() {
    return (
      <React.Fragment>
        <section className="section">
          <Container>
            <Row className="justify-content-center">
              <Col className="text-center">
                <div className="section-title mb-4 pb-2">
                  <h4 className="title mb-4">
                    Everything about our current{" "}
                    <span className="text-primary">Statistic</span>
                  </h4>
                  <p className="text-muted para-desc mx-auto mb-0">
                    Start your business with{" "}
                    <span className="text-primary fw-bold">
                      Celebesherbs.
                    </span>{" "}
                    We would like to invite you to explore the possibilities of importing herbs and spices from Indonesia. 
                    
                  </p>
                </div>
              </Col>
            </Row>

            <Row id="counter">
              {/* counter */}
              <Counter2 counters={this.state.counters} />
            </Row>
          </Container>

          <Container className="mt-100 mt-60">
            <Row className="align-items-center">
              <Col lg="7" md="6">               
                  <div className="accordion" id="accordionExample">
                    <Card className="border-0 rounded mb-2">
                      <Link
                        to="#"
                        onClick={this.t_col1}
                        className={
                          this.state.col1
                            ? "faq position-relative text-primary"
                            : "faq position-relative text-dark"
                        }
                      >
                        <CardHeader
                          className="border-0 bg-light p-3 pe-5"
                          id="headingOne"
                        >
                          <h6 className="title mb-0">
                          What countries do you export to? 
                            <i
                              className={
                                this.state.col1
                                  ? "mdi mdi-chevron-up float-end"
                                  : "mdi mdi-chevron-down float-end"
                              }
                            ></i>
                          </h6>
                        </CardHeader>
                      </Link>
                      <Collapse isOpen={this.state.col1}>
                        <CardBody>
                          <p className="text-muted mb-0 faq-ans">
                          We offer global delivery to all countries around the world.
                          </p>
                        </CardBody>
                      </Collapse>
                    </Card>

                    <Card className="border-0 rounded mb-2">
                      <Link
                        to="#"
                        onClick={this.t_col2}
                        className={
                          this.state.col2
                            ? "faq position-relative text-primary"
                            : "faq position-relative text-dark"
                        }
                      >
                        <CardHeader
                          className="border-0 bg-light p-3 pe-5"
                          id="headingTwo"
                        >
                          <h6 className="title mb-0">
                            {" "}
                            What payment methods do you accept?
                            <i
                              className={
                                this.state.col2
                                  ? "mdi mdi-chevron-up float-end"
                                  : "mdi mdi-chevron-down float-end"
                              }
                            ></i>
                          </h6>
                        </CardHeader>
                      </Link>
                      <Collapse isOpen={this.state.col2}>
                        <CardBody>
                          <p className="text-muted mb-0 faq-ans">
                          We accept payment via PayPal, bank transfer, Visa and Mastercard.
                          </p>
                        </CardBody>
                      </Collapse>
                    </Card>

                    <Card className="border-0 rounded mb-2">
                      <Link
                        to="#"
                        onClick={this.t_col3}
                        className={
                          this.state.col3
                            ? "faq position-relative text-primary"
                            : "faq position-relative text-dark"
                        }
                      >
                        <CardHeader
                          className="border-0 bg-light p-3 pe-5"
                          id="headingfive"
                        >
                          <h6 className="title mb-0">
                            {" "}
                            How long will my order take to arrive? 
                            <i
                              className={
                                this.state.col3
                                  ? "mdi mdi-chevron-up float-end"
                                  : "mdi mdi-chevron-down float-end"
                              }
                            ></i>
                          </h6>
                        </CardHeader>
                      </Link>
                      <Collapse isOpen={this.state.col3}>
                        <CardBody>
                          <p className="text-muted mb-0 faq-ans">
                          Delivery times vary depending on your location, but typically orders arrive within seven business days.
                          </p>
                        </CardBody>
                      </Collapse>
                    </Card>

                    <Card className="border-0 rounded mb-2">
                      <Link
                        to="#"
                        onClick={this.t_col4}
                        className={
                          this.state.col4
                            ? "faq position-relative text-primary"
                            : "faq position-relative text-dark"
                        }
                      >
                        <CardHeader
                          className="border-0 bg-light p-3 pe-5"
                          id="headingfive"
                        >
                          <h6 className="title mb-0">
                          How do I know that the herbs and spices I'm ordering are from Indonesia? 
                            <i
                              className={
                                this.state.col4
                                  ? "mdi mdi-chevron-up float-end"
                                  : "mdi mdi-chevron-down float-end"
                              }
                            ></i>
                          </h6>
                        </CardHeader>
                      </Link>
                      <Collapse isOpen={this.state.col4}>
                        <CardBody>
                          <p className="text-muted mb-0 faq-ans">
                          We are committed to providing only the highest quality of herbs and spices. All of our products are sourced directly from Indonesia and are verified by our quality assurance team prior to shipment.
                          </p>
                        </CardBody>
                      </Collapse>
                    </Card>
                  </div>
                
              </Col>

              <Col lg="5" md="6" className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                <img src={faq} alt="" />
              </Col>
            </Row>
          </Container>

          <Container className="mt-100 mt-60">
            <Row className="justify-content-center">
              <Col xs="12" className="text-center">
                <div className="section-title">
                  <h4 className="title mb-4">Contact Us</h4>
                  <p className="text-muted para-desc mx-auto">
                    Visit{" "}
                    <span className="text-primary fw-bold">
                      Celebesherbs
                    </span>{" "}
                      warehouse. <br/> PT Celebesherbs Berkah Makmur. <br/>Location: Kawasan Industri Patte`ne Business Park, Maros. 
                  </p>

                  <div className="watch-video mt-4 pt-2">
                    <Link to= {{ pathname: "https://wa.me/6281290661714" }} target="_blank" className="btn btn-primary m-1">Call {" "}
                    <span className="badge rounded-pill bg-danger ms-2">US</span></Link>{" "}
                    <Link to= {{ pathname: "mailto:admin@celebesherbs.com" }} target="_blank" onClick={this.openModal} className="btn btn-icon btn-pills btn-primary m-1 lightbox">
                      <FeatherIcon
                        icon="mail"
                        className="icons"
                      />
                    </Link><span className="fw-bold text-uppercase small align-middle ms-1">Email</span>
                    <ModalVideo
                      channel="youtube"
                      isOpen={this.state.isOpen}
                      videoId="yba7hPeTSjk"
                      onClose={() => this.setState({ isOpen: false })}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Faqs;
