import React from "react";
import ReactDOM from "react-dom/client";
//import App from "./App";
// import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from './reportWebVitals';

// Costum

import NavBar from "./pages/Navbar";
import Section from "./pages/Section";
import Feature from "./pages/Feature";
import KeyFeature from "./pages/KeyFeature";
import Work from "./pages/Work";
import Portfolio from "./pages/Portfolio";
import Partners from "./pages/Partners";
import Team from "./pages/Team";
import Faqs from "./pages/Faqs";
import Footer from "./pages/Footer";

// CSS

// Import Css
import "./assets/css/materialdesignicons.min.css"
import "./Apps.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    <BrowserRouter>
    
    <NavBar />
    <Section />
    <Partners />    
    <Feature />      
    <KeyFeature />  
    <Partners />
    <Work />
    <Team />    
    <Portfolio />
    <Faqs />
    <Footer />

    </BrowserRouter>
  </React.Fragment>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
reportWebVitals();
