import React, { useState } from 'react';
import { Card, CardBody, Container, Row, Col, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { Link } from 'react-router-dom';
//import NavBar from "./Portfolio/NavBar";

//import Images
import work1  from "../assets/images/celebes/1.jpeg";
import work2  from "../assets/images/celebes/2.jpeg";
import work3  from "../assets/images/celebes/3.jpeg";
import work4  from "../assets/images/celebes/4.jpeg";
import work5  from "../assets/images/celebes/5.jpeg";
import work6  from "../assets/images/celebes/6.jpeg";
import work7  from "../assets/images/celebes/7.jpeg";
import work8  from "../assets/images/celebes/8.jpeg";
import work9  from "../assets/images/celebes/9.jpeg";
import work10 from "../assets/images/celebes/10.jpeg";
import work11 from "../assets/images/celebes/11.jpeg";
import work12 from "../assets/images/celebes/12.jpeg";
import work13 from "../assets/images/celebes/13.jpeg";
import work14 from "../assets/images/celebes/14.jpeg";
import work15 from "../assets/images/celebes/15.jpeg";
import work16 from "../assets/images/celebes/16.jpeg";
import work17 from "../assets/images/celebes/17.jpeg";
import work18 from "../assets/images/celebes/18.jpeg";
import work19 from "../assets/images/celebes/19.jpeg";
import work20 from "../assets/images/celebes/20.jpeg";
import work21 from "../assets/images/celebes/21.jpeg";
import work22 from "../assets/images/celebes/22.jpeg";
import work23 from "../assets/images/celebes/23.jpeg";
//import Footer4 from "../PageFooterLayouts/Footer4";

import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import ThemeSwitcher from '../components/Layout/ThemeSwitcher';

//Import Icons
import FeatherIcon from 'feather-icons-react';

//Import Light box
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const images = [
    work1,
    work2,
    work3,
    work4,
    work5,
    work6,
    work7,
    work8,
    work9,
    work10,
    work11,
    work12,
    work13,
    work14,
    work15,
    work16,
    work17,
    work18,
    work19,
    work20,
    work21,
    work22,
    work23,
];

const PageMasonryTwo = () => {
    const [filter, setFilter] = useState("All");

    const [photoIndex, setphotoIndex] = useState(0);
    const [isGallery, setisGallery] = useState(false);

    const masnoryTwoData = [
        {
            id: 1,
            img: work1,
            subTitle: "Certification",
            category: "Legal",
            categories: "legal",
            imgIndex: 0,
        },
        {
            id: 2,
            img: work2,
            subTitle: "Cofee Display",
            category: "Expo",
            categories: "expo",
            imgIndex: 1,
        },
        {
            id: 3,
            img: work3,
            subTitle: "Trade Expo",
            category: "Expo",
            categories: "expo",
            imgIndex: 2,
        },
        {
            id: 4,
            img: work4,
            subTitle: "Trade Expo",
            category: "Expo",
            categories: "expo",
            imgIndex: 3,
        },
        {
            id: 5,
            img: work5,
            subTitle: "Trade Expo",
            category: "Expo",
            categories: "expo",
            imgIndex: 4,
        },
        {
            id: 6,
            img: work6,
            subTitle: "Cloves",
            category: "Product",
            categories: "product",
            imgIndex: 5,
        },
        {
            id: 7,
            img: work7,
            subTitle: "Pepper",
            category: "Product",
            categories: "product",
            imgIndex: 5,
        },
        {
            id: 8,
            img: work8,
            subTitle: "Workshop",
            category: "Expo",
            categories: "expo",
            imgIndex: 7,
        },
        {
            id: 9,
            img: work9,
            subTitle: "Venue",
            category: "Expo",
            categories: "expo",
            imgIndex: 8,
        },
        {
            id: 10,
            img: work10,
            subTitle: "Rahmatullah",
            category: "Expo",
            categories: "expo",
            imgIndex: 9,
        },
        {
            id: 11,
            img: work11,
            subTitle: "Container Loading",
            category: "Warehouse",
            categories: "warehouse",
            imgIndex: 10,
        },
        {
            id: 12,
            img: work12,
            subTitle: "Empty Container",
            category: "Warehouse",
            categories: "warehouse",
            imgIndex: 11,
        },
        {
            id: 13,
            img: work13,
            subTitle: "Pepper",
            category: "Product",
            categories: "product",
            imgIndex: 12,
        },
        {
            id: 14,
            img: work14,
            subTitle: "White Pepper",
            category: "Product",
            categories: "product",
            imgIndex: 13,
        },
        {
            id: 15,
            img: work15,
            subTitle: "Black Pepper",
            category: "Product",
            categories: "product",
            imgIndex: 14,
        },
        {
            id: 16,
            img: work16,
            subTitle: "Herbal Wood",
            category: "Product",
            categories: "product",
            imgIndex: 15,
        },
        {
            id: 17,
            img: work17,
            subTitle: "Candlenut",
            category: "Product",
            categories: "product",
            imgIndex: 16,
        },
        {
            id: 18,
            img: work18,
            subTitle: "Candlenut",
            category: "Product",
            categories: "product",
            imgIndex: 17,
        },
        {
            id: 19,
            img: work19,
            subTitle: "Rahmatullah",
            category: "Warehouse",
            categories: "warehouse",
            imgIndex: 18,
        },
        {
            id: 20,
            img: work20,
            subTitle: "Black Pepper",
            category: "Product",
            categories: "product",
            imgIndex: 19,
        },
        {
            id: 21,
            img: work21,
            subTitle: "Black Pepper",
            category: "Product",
            categories: "product",
            imgIndex: 20,
        },
        {
            id: 22,
            img: work22,
            subTitle: "Pepper",
            category: "Product",
            categories: "product",
            imgIndex: 21,
        },
        {
            id: 23,
            img: work23,
            subTitle: "Rahmatullah",
            category: "Warehouse",
            categories: "warehouse",
            imgIndex: 22,
        },

        
    ];

    return (
        <React.Fragment>
            
            <section className="bg-half-80 bg-light d-table w-100">
                <Container>
                    <Row className="mt-5 justify-content-center">
                        <Col lg={12} className="text-center">
                            <div className="pages-heading">
                                <h4 className="title mb-0"> Gallery </h4>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-color-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="section">
                <Container>
                    <Row className="justify-content-center">
                        <Col className="col-12 mb-4 filters-group-wrap">
                            <div className="filters-group">
                                <ul className="container-filter list-inline mb-0 filter-options text-center">
                                    <li
                                        onClick={() => setFilter("All")}
                                        className={filter === "All" ? "list-inline-item categories-name border text-dark rounded active" : "list-inline-item categories-name border text-dark rounded"}
                                        data-group="all">All</li>{" "}
                                    <li
                                        onClick={() => setFilter("legal")}
                                        className={filter === "Legal" ? "list-inline-item categories-name border text-dark rounded active" : "list-inline-item categories-name border text-dark rounded"}
                                        data-group="legal">Legal</li>{" "}
                                    <li
                                        onClick={() => setFilter("product")}
                                        className={filter === "Product" ? "list-inline-item categories-name border text-dark rounded active" : "list-inline-item categories-name border text-dark rounded"}
                                        data-group="product">Product</li>{" "}
                                    <li
                                        onClick={() => setFilter("warehouse")}
                                        className={filter === "Warehouse" ? "list-inline-item categories-name border text-dark rounded active" : "list-inline-item categories-name border text-dark rounded"}
                                        data-group="warehouse">Warehouse</li>{" "}
                                    <li
                                        onClick={() => setFilter("expo")}
                                        className={filter === "Expo" ? "list-inline-item categories-name border text-dark rounded active" : "list-inline-item categories-name border text-dark rounded"}
                                        data-group="expo">Expo</li>{" "}
                                </ul>
                            </div>
                        </Col>
                    </Row>

                    <Row id="grid">
                        <ResponsiveMasonry
                            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 4 }}
                        >
                            <Masonry columnsCount={4} gutter="10px">
                                {(masnoryTwoData.filter(({ categories }) => filter === categories || filter === "All")).map((item, key) => (
                                    <Col key={key} className="col-12 spacing picture-item" data-groups='["legal"]'>
                                        <Card className="border-0 work-container work-primary work-modern position-relative d-block overflow-hidden rounded">
                                            <CardBody className="p-0">
                                                <img src={item.img} className="img-fluid" alt="work" />
                                                <div className="overlay-work"></div>
                                                <div className="content">
                                                    <h5 className="mb-1"><Link to="/portfolio-detail-one" className="text-white title">{item.subTitle}</Link></h5>
                                                    <h6 className="text-white-50 tag mt-1 mb-0">{item.category}</h6>
                                                </div>
                                                <div className="icons text-center">
                                                    <Link to="#" className="work-icon bg-white d-inline-block rounded-pill lightbox">
                                                        <i><FeatherIcon icon="camera" onClick={() => { setisGallery(true); setphotoIndex(item.imgIndex) }} className="fea icon-sm image-icon" /></i></Link>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                            </Masonry>
                        </ResponsiveMasonry>
                        {isGallery ? (
                            <Lightbox
                                mainSrc={images[photoIndex]}
                                nextSrc={images[(photoIndex + 1) % images.length]}
                                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                                enableZoom={true}
                                onCloseRequest={() => {
                                    setisGallery(false);
                                }}
                                onMovePrevRequest={() => {
                                    setphotoIndex((photoIndex + images.length - 1) % images.length);
                                }}
                                onMoveNextRequest={() => {
                                    setphotoIndex((photoIndex + 1) % images.length);
                                }}
                                imageCaption={"Project " + parseFloat(photoIndex + 1)}
                            />
                        ) : null}
                    </Row>

                    <Row>
                        <Col className="col-12 mt-4 pt-2">
                            <Pagination listClassName="justify-content-center mb-0">
                                <PaginationItem><PaginationLink to="#">Prev</PaginationLink></PaginationItem>
                                <PaginationItem active><PaginationLink to="#">1</PaginationLink></PaginationItem>
                                <PaginationItem><PaginationLink to="#">2</PaginationLink></PaginationItem>
                                <PaginationItem><PaginationLink to="#">3</PaginationLink></PaginationItem>
                                <PaginationItem><PaginationLink to="#">Next</PaginationLink></PaginationItem>
                            </Pagination>
                        </Col>
                    </Row>

                </Container>
            </section>
            
            <ThemeSwitcher/>
        </React.Fragment>
    );
}

export default PageMasonryTwo;