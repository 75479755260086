import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Popup from '../components/Layout/popup';

//import Images
import homebg from "../assets/images/indospicesherbs.png";

const Section = () => {
    return (
        <React.Fragment>
            <section className="bg-home d-flex align-items-center background-effect bg-soft-primary" id="home">
            <Container className="container z-index-1">
                <Row className="align-items-center">
                    <Col lg={6} md={6}>
                        <div className="title-heading">
                            <h4 className="display-5 fw-bold text-dark mb-4">Celebesherbs,<br/> from Indonesia <br/> to the World</h4>
                            <p className="para-desc mx-auto text-muted mb-0">Introducing Celebes Spices and Herbs to the world, supplying Palm Sugar, Bricket, Seaweed, Coconut Oil, Coconut Broomstick, Coffee, Seaweed and Cocoa since 2022. We provide a highest quality of seaweed, all of which are harvested, washed, dried, and packaged both by hand and machine.</p>
                        
                            <div className="mt-4">
                                <Link to={{ pathname:"https://wa.me/6281290661714" }} target="_blank" className="btn btn-primary me-1"><i className="uil uil-whatsapp"></i> Contact Us</Link>{" "}
                                <Link to={{ pathname: "mailto:admin@celebesherbs.com" }} target="_blank" className="btn btn-primary me-1"><i className="uil uil-writing"></i> Send Us Email</Link>
                            </div>
                        </div>
                    </Col>

                    <Col lg={6} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <img src={homebg} className="img-fluid" alt=""/>
                    </Col>
                </Row>
            </Container>

            <ul className="circles p-0 mb-0">
                <li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li>
            </ul>
        </section>

        <Popup/>
        </React.Fragment>
    );
}

export default Section;